import moment from 'moment/moment'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ClaimsConfig} from '../../app/modules/claims/core/_const'
import {UNIT_VALUES} from '../../app/modules/orders/core/_const'
import {
  FORMAT_DATE,
  REGEX_EMAIL,
  REGEX_HTS_CODE,
  REGEX_INPUT_TEXT,
  REGEX_PASSWORD,
  REGEX_PHONE,
  REGEX_SHOPIFY_SUBDOMAIN,
  REGEX_STATE,
  REGEX_URL,
  REGEX_ZIP_CODE,
} from '../constants'
import {yupHelper} from '../helpers'

const UseYupValidate = () => {
  const intl = useIntl()
  const yup = yupHelper(intl)

  const stringYup = useCallback(
    (valueLabel, name, required = true) => {
      switch (valueLabel) {
        case 'no_limit':
          return yup.string({
            name: name,
            required: required,
            trim: true,
          })

        default:
          return yup.string({
            name: name,
            required: required,
            trim: true,
            max: {value: valueLabel, label: valueLabel},
          })
      }
    },
    [yup]
  )

  const numberYup = {
    parcel: useCallback(
      (name, required = true, packageType: 'hasPackage' | '' = '') => {
        let yup = Yup.number()
          .nullable()
          .typeError(
            intl.formatMessage(
              {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
              {input: intl.formatMessage({id: name.toUpperCase()})}
            )
          )
          .positive()
          .moreThan(
            0,
            intl.formatMessage(
              {id: 'INPUT_MUST_BE_GREATER_THAN'},
              {input: intl.formatMessage({id: name?.toUpperCase()}), min: 0}
            )
          )

        if (name === 'weight') {
          Object.entries(
            packageType === 'hasPackage' ? UNIT_VALUES.WEIGHT_CARRIER : UNIT_VALUES.WEIGHT
          ).forEach(([unit, {max}]: any) => {
            yup = yup.when(['weight_unit'], {
              is: unit,
              then: Yup.number()
                .nullable()
                .max(
                  max,
                  intl.formatMessage(
                    {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
                    {input: intl.formatMessage({id: name.toUpperCase()}), max: `${max} ${unit}`}
                  )
                ),
            })
          })
        } else {
          Object.entries(UNIT_VALUES.DIMENSION).forEach(([unit, {max}]: any) => {
            yup = yup.when(['dimension_unit'], {
              is: unit,
              then: Yup.number()
                .nullable()
                .max(
                  max,
                  intl.formatMessage(
                    {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
                    {input: intl.formatMessage({id: name.toUpperCase()}), max: `${max} ${unit}`}
                  )
                ),
            })
          })

          Object.entries(UNIT_VALUES.DIMENSION).forEach(([unit, {max}]: any) => {
            yup = yup.when(['unit'], {
              is: unit,
              then: Yup.number()
                .nullable()
                .max(
                  max,
                  intl.formatMessage(
                    {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
                    {input: intl.formatMessage({id: name.toUpperCase()}), max: `${max} ${unit}`}
                  )
                ),
            })
          })
        }

        if (required) {
          yup = yup.required(
            intl.formatMessage(
              {id: 'INPUT_IS_REQUIRED'},
              {input: intl.formatMessage({id: name.toUpperCase()})}
            )
          )
        }
        return yup
      },
      [intl]
    ),
    unitPrice: useCallback(
      (name = 'UNIT_PRICE', required = true) => {
        return yup.number({
          name: name,
          required: required,
          trim: true,
          min: {
            value: 0,
            label: 0,
          },
          max: {
            value: 1000000,
            label: '$1,000,000',
          },
        })
      },
      [yup]
    ),
    quantity: useCallback(
      (name = 'QUANTITY', required = true) => {
        return yup.number({
          name: name,
          required: required,
          trim: true,
          integer: true,
          min: {
            value: 0,
            label: 0,
          },
          max: {
            value: 1000000,
            label: '1,000,000',
          },
        })
      },
      [yup]
    ),
    amount: useCallback(
      (name, required = true, value = 10000000, label = '$10,000,000') => {
        return yup.number({
          name: name,
          required: required,
          trim: true,
          min: {
            value: 0.1,
            label: 0,
          },
          max: {
            value: value,
            label: label,
          },
        })
      },
      [yup]
    ),
    journey_info: useCallback(
      (config: {
        name: string
        required?: boolean
        integer?: boolean
        min?: {label: string; value: number}
        max?: null | {label: string; value: number}
        moreThan?: boolean
      }) => {
        const {
          name = '',
          required = false,
          integer = true,
          min = {
            value: 1,
            label: '1',
          },
          max = null,
          moreThan = false,
        } = config

        let yup = Yup.number()
          .nullable()
          .typeError(
            intl.formatMessage(
              {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
              {input: intl.formatMessage({id: name})}
            )
          )

        if (moreThan) {
          yup = yup
            .positive()
            .moreThan(
              min.value,
              intl.formatMessage(
                {id: 'INPUT_MUST_BE_GREATER_THAN'},
                {input: intl.formatMessage({id: name}), min: min?.label}
              )
            )
        }
        if (!moreThan && min) {
          yup = yup.min(
            min?.value,
            intl.formatMessage(
              {id: 'INPUT_MUST_BE_GREATER_THAN_OR_EQUAL_TO'},
              {input: intl.formatMessage({id: name}), min: min?.label}
            )
          )
        }
        if (integer) {
          yup = yup.integer(
            intl.formatMessage(
              {id: 'INPUT_ONLY_ACCEPT_INTEGERS'},
              {input: intl.formatMessage({id: name})}
            )
          )
        }
        if (required) {
          yup = yup.required(
            intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
          )
        }
        if (max) {
          yup = yup.max(
            max?.value,
            intl.formatMessage(
              {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
              {input: intl.formatMessage({id: name}), max: max?.label}
            )
          )
        }
        return yup
      },
      [intl]
    ),
  }

  const regexYup = {
    country: useCallback(
      (name = 'COUNTRY') => {
        return Yup.string()
          .required(
            intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
          )
          .max(
            2,
            intl.formatMessage(
              {id: 'PLEASE_USE_A_TWO_LETTER_CODE'},
              {input: intl.formatMessage({id: name})}
            )
          )
          .nullable()
          .trim()
      },
      [intl]
    ),
    state: useCallback(
      (name = 'STATE', required = true) => {
        let yup = Yup.string()
          .matches(
            REGEX_STATE,
            intl.formatMessage(
              {id: 'PLEASE_USE_A_TWO_LETTER_CODE'},
              {input: intl.formatMessage({id: name})}
            )
          )
          .max(
            2,
            intl.formatMessage(
              {id: 'PLEASE_USE_A_TWO_LETTER_CODE'},
              {input: intl.formatMessage({id: name})}
            )
          )
          .nullable()
          .trim()
        if (required) {
          yup = yup.required(
            intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
          )
        }
        return yup
      },
      [intl]
    ),
    zipCode: useCallback(
      (name = 'POSTAL_CODE', required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 255,
            label: 255,
          },
          regex: {
            value: REGEX_ZIP_CODE,
            label: 'THE_VALUE_IS_NOT_A_VALID_ZIP_CODE',
          },
        })
      },
      [yup]
    ),
    url: useCallback(
      (name, required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_URL,
            label: 'PLEASE_ENTER_VALID_URL',
          },
        })
      },
      [yup]
    ),
    shop: useCallback(
      (name, required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 255,
            label: 255,
          },
          regex: {
            value: REGEX_SHOPIFY_SUBDOMAIN,
            label: 'PLEASE_ENTER_VALID_SHOPIFY_SUBDOMAIN',
          },
        })
      },
      [yup]
    ),
    storeLink: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Shopify ' + intl.formatMessage({id: 'STORE_DOMAIN'})}
        )
      )
      .matches(
        REGEX_SHOPIFY_SUBDOMAIN,
        intl.formatMessage({id: 'PLEASE_ENTER_VALID_SHOPIFY_SUBDOMAIN'})
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'Shopify ' + intl.formatMessage({id: 'STORE_DOMAIN'}), max: 255}
        )
      )
      .trim(),
    customLink: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: intl.formatMessage({id: 'CUSTOM_LINK'})}
        )
      )
      .matches(REGEX_URL, intl.formatMessage({id: 'PLEASE_ENTER_VALID_URL'}))
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: intl.formatMessage({id: 'CUSTOM_LINK'}), max: 255}
        )
      )
      .trim(),
    inputText: useCallback(
      (name, required = true, max = 255) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: max,
            label: max,
          },
          regex: {
            value: REGEX_INPUT_TEXT,
            label: 'SPECIAL_CHARACTERS_NOT_ALLOWED',
          },
        })
      },
      [yup]
    ),
  }

  const arrayRequiredYup = useCallback(
    (name, min = 1, toBe = 'IS') => {
      return Yup.array().min(
        min,
        intl.formatMessage(
          {id: 'INPUT_BE_REQUIRED'},
          {input: intl.formatMessage({id: name}), be: intl.formatMessage({id: toBe})}
        )
      )
    },
    [intl]
  )

  const dateYup = useCallback(
    (name, minDate = '2000-01-01T00:00:00Z', required = true, maxDate = '2099-12-31T23:59:59Z') => {
      let yup = Yup.date()
        .nullable()
        .typeError(
          intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
        )

      if (minDate) {
        yup = yup.min(
          minDate,
          intl.formatMessage(
            {id: 'INPUT_MUST_BE_FROM_MIN_ONWARDS'},
            {
              input: intl.formatMessage({id: name}),
              min: moment(minDate).format(FORMAT_DATE.DATE),
            }
          )
        )
      }
      if (maxDate) {
        yup = yup.max(
          maxDate,
          intl.formatMessage(
            {id: 'INPUT_MUST_NOT_OVER_MAX'},
            {
              input: intl.formatMessage({id: name}),
              max: moment(maxDate).format(FORMAT_DATE.DATE),
            }
          )
        )
      }
      if (required) {
        yup = yup.required(
          intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
        )
      }
      return yup
    },
    [intl]
  )

  const infoYup = {
    phone: useCallback(
      (name = 'PHONE', required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 20,
            label: 20,
          },
          regex: {
            value: REGEX_PHONE,
            label: 'PLEASE_ENTER_VALID_PHONE',
          },
        })
      },
      [yup]
    ),
    email: useCallback(
      (name = 'EMAIL', required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 100,
            label: 100,
          },
          regex: {
            value: REGEX_EMAIL,
            label: 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
          },
        })
      },
      [yup]
    ),
  }

  // BEGIN: auth
  const authYup = {
    password: useCallback(
      (required = true) => {
        return yup.string({
          name: 'PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_PASSWORD,
            label: 'PLEASE_ENTER_VALID_PASSWORD',
          },
        })
      },
      [yup]
    ),
    passwordConfirmation: useCallback(
      (required = true) => {
        return yup.string({
          name: 'CONFIRM_PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          when: {
            target: 'password',
            is: (val: string) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              intl.formatMessage({id: 'PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH'})
            ),
          },
        })
      },
      [intl, yup]
    ),
    acceptTerms: Yup.bool().oneOf(
      [true],
      intl.formatMessage({id: 'YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS'})
    ),
    acceptHazardousCheckbox: Yup.bool().oneOf(
      [true],
      intl.formatMessage({id: 'YOU_MUST_ACCEPT_HAZARDOUS'})
    ),
  }
  // END: auth

  // BEGIN: Claims
  const claimYup = {
    specificItemType: yup.string({
      name: 'SPECIFIC_ITEM_TYPE',
      when: {
        target: 'claim_item_type',
        is: (val) => val === ClaimsConfig.ITEM_TYPE_OTHER,
        then: stringYup(50, 'SPECIFIC_ITEM_TYPE'),
      },
    }),

    replacementTrackingNumber: yup.string({
      name: 'REPLACEMENT_TRACKING_NUMBER',
      when: {
        target: 'claim_is_replace_package',
        is: true,
        then: stringYup(50, 'REPLACEMENT_TRACKING_NUMBER'),
      },
    }),
  }
  // END: Claims

  // BEGIN: Orders
  const orderYup = {
    companyFirstName: useCallback(
      (name) => {
        return Yup.string()
          .required(
            intl.formatMessage(
              {id: 'INPUT_REQUIRED_IF_EMPTY'},
              {
                input: intl.formatMessage({id: name}),
                value: intl.formatMessage({id: name === 'FIRST_NAME' ? 'COMPANY' : 'FIRST_NAME'}),
              }
            )
          )
          .max(
            255,
            intl.formatMessage(
              {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
              {input: intl.formatMessage({id: name}), max: 255}
            )
          )
          .nullable()
          .trim()
      },
      [intl]
    ),

    insuredAmount: Yup.number()
      .typeError(
        intl.formatMessage(
          {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
          {input: intl.formatMessage({id: 'INSURANCE_AMOUNT'})}
        )
      )
      .min(
        0,
        intl.formatMessage(
          {id: 'INPUT_MUST_BE_GREATER_THAN_OR_EQUAL_TO'},
          {
            input: intl.formatMessage({id: 'INSURANCE_AMOUNT'}),
            min: 0,
          }
        )
      )
      .max(
        5000,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
          {
            input: intl.formatMessage({id: 'INSURANCE_AMOUNT'}),
            max: '5000',
          }
        )
      )
      .nullable(),
    reference: useCallback(
      (name, required = false) => {
        return stringYup(30, name, required)
      },
      [stringYup]
    ),
    shipByDate: dateYup('SHIPMENT_DATE', moment().startOf('day').format(), false),
    customs: {
      content: stringYup(25, 'CONTENT'),
      content_description: Yup.string().when('content', {
        is: (val) => val === 'other',
        then: stringYup(25, 'DESCRIPTION'),
        otherwise: stringYup(25, 'DESCRIPTION', false),
      }),
      description: stringYup(255, 'DESCRIPTION'),
      hsTariffNumber: stringYup(50, 'HS_TARIFF_NUMBER', false),
      country: stringYup(2, 'COUNTRY', false),
      quantity: yup.number({
        name: 'QUANTITY',
        required: true,
        trim: true,
        integer: true,
        min: {
          value: 0.1,
          label: 0,
        },
        max: {
          value: 9999,
          label: '9,999',
        },
      }),
      value: yup.number({
        name: 'VALUE',
        required: true,
        trim: true,
        min: {
          value: 0.1,
          label: 0,
        },
        max: {
          value: 99999.99,
          label: '$99,999.99',
        },
      }),
      weight: numberYup.parcel('WEIGHT'),
    },
    orderA1: {
      content: stringYup(255, 'CONTENT'),
      description: stringYup(255, 'DESCRIPTION'),
      htsCode: useCallback(
        (name = 'HTS_CODE', required = true) => {
          return yup.string({
            name: name,
            required: required,
            trim: true,
            regex: {
              value: REGEX_HTS_CODE,
              label: 'INVALID_HTS_CODE_PLEASE_ENTER_IT_IN_THE_FORMAT',
            },
          })
        },
        [yup]
      ),
      containerId: useCallback(
        (required = true) => {
          return stringYup(100, 'CONTAINER_ID', required)
        },
        [stringYup]
      ),
      country: regexYup.country('COUNTRY_OF_ORIGIN'),
      quantity: yup.number({
        name: 'QUANTITY',
        required: true,
        trim: true,
        integer: true,
        min: {
          value: 0.1,
          label: 0,
        },
        max: {
          value: 1000000,
          label: '1,000,000',
        },
      }),
      value: numberYup.journey_info({
        name: 'VALUE',
        required: true,
        min: {
          value: 0,
          label: '0',
        },
        max: {
          value: 1000000,
          label: '$1,000,000',
        },
        integer: false,
        moreThan: true,
      }),
      weight: numberYup.journey_info({
        name: 'WEIGHT',
        required: true,
        min: {
          value: 0,
          label: '0',
        },
        max: {
          value: 1000,
          label: '1,000',
        },
        integer: false,
        moreThan: true,
      }),
    },
    checkIntlWeight: Yup.bool().oneOf(
      [false],
      intl.formatMessage({
        id: 'PACKAGE_WEIGHT_MUST_BE_HEAVIER_OR_EQUAL_TO_CUSTOMS_ITEMS_WEIGHT',
      })
    ),
  }
  // END: Orders

  // BEGIN: Batches
  const batchesYup = {
    existingBatch: Yup.object().required(
      intl.formatMessage(
        {id: 'INPUT_IS_REQUIRED'},
        {input: intl.formatMessage({id: 'SELECT_A_BATCH'})}
      )
    ),
  }
  // END: Batches

  // BEGIN: settings
  const settingsYup = {
    token: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'TOKEN'})}
        )
      )
      .trim(),
    apiKey: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'API_KEY'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'API_KEY'}), max: 255}
        )
      )
      .trim(),
    secretKey: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'API_SECRET'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'API_SECRET'}), max: 255}
        )
      )
      .trim(),
  }
  // END: settings

  const walmartYup = {
    token: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Walmart ' + intl.formatMessage({id: 'TOKEN'})}
        )
      )
      .trim(),
    apiKey: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Walmart ' + intl.formatMessage({id: 'API_KEY'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'Walmart ' + intl.formatMessage({id: 'API_KEY'}), max: 255}
        )
      )
      .trim(),
    secretKey: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Walmart ' + intl.formatMessage({id: 'API_SECRET'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'Walmart ' + intl.formatMessage({id: 'API_SECRET'}), max: 255}
        )
      )
      .trim(),
  }

  const temuYup = {
    sellerType: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Temu ' + intl.formatMessage({id: 'SELLER_TYPE'})}
        )
      )
      .trim(),
    storeName: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Temu ' + intl.formatMessage({id: 'STORE_NAME'})}
        )
      )
      .max(
        50,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'Temu ' + intl.formatMessage({id: 'STORE_NAME'}), max: 50}
        )
      )
      .trim(),
    accessToken: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Temu ' + intl.formatMessage({id: 'ACCESS_TOKEN'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {
            input:
              intl.formatMessage({id: 'TEMU'}) + ' ' + intl.formatMessage({id: 'ACCESS_TOKEN'}),
            max: 255,
          }
        )
      )
      .trim(),
    region: Yup.object().required(
      intl.formatMessage(
        {id: 'INPUT_IS_REQUIRED'},
        {input: 'Temu ' + intl.formatMessage({id: 'REGION'})}
      )
    ),
  }
  return {
    stringYup,
    numberYup,
    regexYup,
    arrayRequiredYup,
    dateYup,
    infoYup,
    authYup,
    claimYup,
    orderYup,
    batchesYup,
    settingsYup,
    walmartYup,
    temuYup,
  }
}

export default UseYupValidate
