import React from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {ROUTES} from '../../../../_gori/constants/_routes'

export function PendingActivation() {
  const intl = useIntl()

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'ACTIVATE_ACCOUNT'})}</h1>
        </div>
        <div className='mb-lg-10 alert bg-light-primary alert-dismissible'>
          <div className='alert-text'>
            <div>{intl.formatMessage({id: 'ACCOUNT_ACTIVATION_INSTRUCTION'})}</div>
          </div>
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Link to={ROUTES.AUTH.LOGIN} className='link-primary fw-bolder'>
            <button
              type='submit'
              id='gori_sign_in_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              {intl.formatMessage({id: 'LOGIN'})}
            </button>
          </Link>
        </div>
      </div>
    </CSSTransition>
  )
}
